<template>
  <div class="booking-form">
    <div class="booking-form-left">
      <div class="booking-form-left-header">
        <h2>{{ spot.name }}</h2>
        <span class="mt-2">{{ $t("COMMON.REFERENCE") }}: {{ spot.code }}</span>
      </div>

      <!-- gallery -->
      <div class="booking-form-left-gallery">
        <div class="main">
          <el-image :src="preview" :fit="'cover'">
            <div slot="placeholder" class="image-slot">
              {{ $t("COMMON.ONGOING_LOADING") }}
            </div>
            <div slot="error" class="el-image__error">
              <i class="el-icon-picture-outline" style="font-size: 2em"></i>
            </div>
          </el-image>
        </div>
        <div class="list">
          <ul>
            <li v-if="spot.picture" @click="() => (preview = $pictureResize(spot.picture, 1000))">
              <el-image :src="$pictureResize(spot.picture, 300)" :fit="'cover'">
                <div slot="error" class="el-image__error">
                  <i class="el-icon-picture-outline" style="font-size: 2em"></i>
                </div>
              </el-image>
            </li>
            <li
              v-for="img in spot.gallery.filter(item => !!item)"
              :key="img"
              @click="() => (preview = $pictureResize(img, 1000))"
            >
              <el-image :src="$pictureResize(img, 300)" :fit="'cover'">
                <div
                  slot="error"
                  class="el-image__error"
                  style="font-size: 2em"
                >
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </li>
          </ul>
        </div>
      </div>

      <div class="booking-form-left-details">
        <div class="details">
          <h2>{{ $t("COMMON.DETAILS") }}</h2>
          <div class="details-items">
            <div class="details-items-item">
              <ul>
                <li>Nom de l'emplacement</li>
                <li>{{ spot.name }}</li>
              </ul>
            </div>
            <div class="details-items-item">
              <ul>
                <li>Type d'emplacement</li>
                <li>{{ spot.spotType?.name }}</li>
              </ul>
            </div>
            <div class="details-items-item">
              <ul>
                <li>Dimension de l'emplacement</li>
                <li>
                  {{ spot.equipment_length }}
                  {{ $t("COMMON.UNIT_FEET") }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- description -->
        <div class="description">
          <h4>{{ $t("COMMON.EXCERPT") }}</h4>
          <p v-html="spot.excerpt"></p>
        </div>

        <!-- capacity -->
        <div class="equipments">
          <h4>{{ $t("SPOTS.SPOT_CAPACITY") }}</h4>
          <ul>
            <li>
              <span class="text">
                <span class="text-bold">
                  {{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}:
                </span>
                {{ spot.adults_capacity }}
              </span>
            </li>
            <li>
              <span class="text">
                <span class="text-bold">
                  {{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}:
                </span>
                {{ spot.children_capacity }}
              </span>
            </li>
            <li v-if="spot.pets_capacity">
              <span class="text">
                <span class="text-bold">
                  {{ $t("SPOTS.SPOT_PETS_CAPACITY") }}:
                </span>
                {{ spot.pets_capacity }}
              </span>
            </li>
          </ul>
        </div>

        <!-- beds -->
        <div
          class="equipments"
          v-if="bedsIsset && (isPropertyFormType || isDefaultFormType)"
        >
          <h4>{{ $t("SPOTS.BEDS") }}</h4>
          <ul>
            <li v-if="spot.beds_king_qty">
              <span class="text">
                {{ $t("SPOTS.BEDS_KING_QTY") }}:
                {{ spot.beds_king_qty }}
              </span>
            </li>
            <li v-if="spot.beds_queen_qty">
              <span class="text">
                {{ $t("SPOTS.BEDS_QUEEN_QTY") }}:
                {{ spot.beds_queen_qty }}
              </span>
            </li>
            <li v-if="spot.beds_double_qty">
              <span class="text">
                {{ $t("SPOTS.BEDS_DOUBLE_QTY") }}:
                {{ spot.beds_double_qty }}
              </span>
            </li>
            <li v-if="spot.beds_twin_qty">
              <span class="text">
                {{ $t("SPOTS.BEDS_TWIN_QTY") }}:
                {{ spot.beds_twin_qty }}
              </span>
            </li>
          </ul>
        </div>

        <div
          class="equipments flex-row align-items-baseline"
          v-if="spot.spot_area && isLandSpotType"
        >
          <h4 class="d-inline-block">{{ $t("SPOTS.SPOT_AREA") }} :</h4>
          <span class="text-sm ml-2">
            {{ spot.spot_area }} ({{ $t("COMMON.UNIT_SQUARE_FOOT") }})
          </span>
        </div>
        <!-- region bathrooms -->
        <div class="equipments" v-if="bathroomIsset && (isPropertyFormType || isDefaultFormType)">
          <h4>{{ $t("SPOTS.BATHROOMS") }}</h4>
          <ul>
            <li v-if="spot.bathroom_shower_qty">
              <span class="text">
                {{ $t("SPOTS.BATHROOM_SHOWER_QTY") }}:
                {{ spot.bathroom_shower_qty }}
              </span>
            </li>
            <li v-if="spot.bathroom_bathtub_qty">
              <span class="text">
                {{ $t("SPOTS.BATHROOM_BATHTUB_QTY") }}:
                {{ spot.bathroom_bathtub_qty }}
              </span>
            </li>
          </ul>
        </div>
        <!-- enregion -->

        <!-- region equipements autorises -->
        <div
          class="equipments flex-row align-items-baseline"
          v-if="spot.allowed_equipment?.length && isLandSpotType"
        >
          <h4 class="d-inline-block">{{ $t("SPOTS.ALLOWED_EQUIPMENT") }} :</h4>
          <allowed-equipments
            class="text-sm ml-2"
            :value="spot.allowed_equipment"
          />
        </div>
        <!-- endregion -->

        <!-- region services inclus -->
        <div class="equipments flex-row align-items-baseline" v-if="spot.services_included?.length && isLandSpotType">
          <h4>{{ $t("SPOTS.SERVICES_INCLUDED") }} :</h4>
          <service-included :value="spot.services_included" />
        </div>

        <!-- region électricité -->
        <div class="equipments flex-row align-items-baseline" v-if="spot.electricity?.length && isLandSpotType">
          <h4 class="d-inline-block">{{ $t("SPOTS.ELECTRICITY") }} :</h4>
          <electricity class="text-sm ml-2" :value="spot.electricity" />
        </div>
        <div class="equipments flex-row align-items-baseline" v-if="spot.kilo_watt && isSeasonalFormType">
          <h4 class="d-inline-block">{{ $t("SPOTS.KILOWATT") }} :</h4>
          <span class="text-sm ml-2">
            {{ spot.kilo_watt }}
          </span>
        </div>

        <div class="equipments flex-row align-items-baseline" v-if="spot.kilo_watt_measure_date && spot.kilo_watt &&  isSeasonalFormType">
          <h4 class="d-inline-block">{{ $t("SPOTS.KILOWATT_DATE") }} :</h4>
          <span class="text-sm ml-2">
            {{ spot.kilo_watt_measure_date | moment("DD/MM/YYYY") }}
          </span>
        </div>
        <!-- endregion -->

        <!-- region extensions passagers -->
        <div class="equipments flex-row align-items-baseline" v-if="spot.passenger_extensions && isLandSpotType">
          <h4>{{ $t("SPOTS.PASSENGER_EXTENSIONS") }}</h4>
          <span class="text-sm ml-2">
            {{ spot.passenger_extensions }}
          </span>
        </div>
        <!-- endregion -->

        <!-- region extensions chauffeur -->
        <div class="equipments flex-row align-items-baseline" v-if="spot.driver_extensions && isLandSpotType">
          <h4>{{ $t("SPOTS.DRIVER_EXTENSIONS") }}</h4>
          <span class="text-sm ml-2">
            {{ spot.driver_extensions }}
          </span>
        </div>
        <!-- endregion -->

        <!-- Equipment -->
        <div class="equipments">
          <h4>{{ $t("SPOTS.SPOT_EQUIPMENTS") }}</h4>
          <ul>
            <li v-for="eq in spot.other_equipments" :key="eq.id">
              <span class="icon">
                <img :src="eq.icon" alt="" />
              </span>
              <span class="text">
                {{ eq.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="booking-form-right">
      <div class="step-header">
        <el-steps :active="step" finish-status="success">
          <el-step title="Sélection"></el-step>
          <el-step title="Clients"></el-step>
        </el-steps>
      </div>

      <booking-spot-selection
        v-if="step === 0"
        :bookingData="booking"
        :editing="true"
        :spot="spot"
        @draftBookingCreated="onDraftBookingCreated"
        @stepChanged="gotToStep"
        ref="bookingSpotSelection"
      />

      <booking-customer-selection
        v-if="step === 1"
        :bookingData="booking"
        :editing="editing"
        :spot="spot"
        @customerSelected="onCustomerSelection"
        @stepChanged="gotToStep"
      />
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  Header,
  Image,
  Row,
  Divider,
  Step,
  Steps,
} from "element-ui";
import {FORM_TYPE_SEASONAL, phoneTypesOptions} from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import { defaultAddressInformation } from "@/constants/defaultAddressInformation";
import "flatpickr/dist/flatpickr.css";
import BookingSpotSelection from "./form/BookingSpotSelection.vue";
import BookingCustomerSelection from "./form/BookingCustomerSelection.vue";
import defaultBooking from "../defaultBooking";
import defaultSpot from "../../../SpotModule/SpotManagement/defaultSpot";
import AllowedEquipments from "@/components/AllowedEquipments";
import Electricity from "@/components/Electricity";
import ServiceIncluded from "@/components/ServiceIncluded.vue";
import {
  BOOKING_FORM_TYPE_DEFAULT,
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_SEASONAL
} from "@/constants/spotCategories";

import { SPOT_STATUS_PARTIALY_AVAILABLE } from "../../../../../constants/common";
import swal from "sweetalert2";


export default {
  layout: "DashboardLayout",

  components: {
    ServiceIncluded,
    BookingCustomerSelection,
    BookingSpotSelection,
    AllowedEquipments,
    Electricity,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
    editing: {
      type: Boolean,
      default: false,
      description: "Form mode (edit = true, create= false)",
    },
  },

  data() {
    let bookingData = { ...this.bookingData };
    bookingData = this.$fillUserOrganizationData(bookingData);

    const preview = this.$pictureResize(this.spot.picture ?? this.spot.gallery[0], 1000);
    return {
      booking: bookingData,
      phoneTypesOptions,
      bookings: [],
      total: 0,
      step: 0,
      showModal: false,
      defaultAddressInformation,
      showBookingCheckModal: false,
      processing: false,
      preview,
      BOOKING_FORM_TYPE_DEFAULT,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
    };
  },

  created() {},

  mounted() {},

  computed: {
    isDefaultFormType: function () {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_DEFAULT;
    },
    isLandFormType: function () {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND;
    },
    isPropertyFormType: function () {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_PROPERTY;
    },
    isSeasonalFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL;
    },
    isLandSpotType() {
      return this.isSeasonalFormType || this.isLandFormType;
    },
    bedsIsset: function () {
      return (
        this.spot.beds_king_qty ||
        this.spot.beds_queen_qty ||
        this.spot.beds_double_qty ||
        this.spot.beds_twin_qty
      );
    },
    bathroomIsset: function() {
      return (this.spot.bathroom_bathtub_qty || this.spot.bathroom_shower_qty) ;
    }
  },

  methods: {
    onFormChanged(booking) {
      this.$emit("formChanged", booking);
    },
    onDraftBookingCreated(booking) {
      this.booking = cloneDeep(booking);
      this.step = 1;
    },
    onCustomerSelection(booking, secure, order) {
      this.$emit("bookingCreated", booking, secure, order);     

      if(this.editing){
        this.$emit("bookingUpdated", booking);
      }
    },

    gotToStep(stepNumber) {
      this.step = stepNumber;
    },

    onSubmit(bookingData) {
      this.$emit("onSubmit", bookingData);
    },

    handleCheckSpotAvailability(data){
      const {spot,start_at,end_at} = data;

    //  console.log("this.$refs => ",data)

      this.$refs.bookingSpotSelection.getUnavailableDates(spot.id,[start_at,end_at])

      const state = spot?.state ?? null;
      if(state && state.status === SPOT_STATUS_PARTIALY_AVAILABLE){
          const start_date = this.$formatDate(start_at,'DD-MM-YYYY');
          const end_date = this.$formatDate(end_at,'DD-MM-YYYY');
           
          swal.fire({
            html : `
                Cet emplacement n'est que partiellement disponible pour les dates que vous avez sélectionnées (du ${start_date} au ${end_date}).
                <strong>Veuillez consulter le calendrier des disponibilités afin d'en savoir plus.</strong>
                `,
            //icon: "warning",
            customClass: {
              popup: "delete-popup",
            },
            buttonsStyling: false,
            showCancelButton: false,
            cancelButtonText: this.$t("COMMON.NO"),
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
           })
      }
    }
  },

  watch: {
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };
          this.preview = this.$pictureResize(this.spot.picture, 1000);
        }
      },
      deep: true,
    },
  },
};
</script>
